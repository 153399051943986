import { IConfig } from 'features/Config/configSlice';
import { UserSessionData } from 'features/User/UserSlice';
import { configSelector } from 'features/Config/configSlice';
import { userSelector } from 'features/User/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartSelector,
  refreshCartActiveSpecialOffers,
  refreshFetchedActiveSpecialOffers,
  setCalculatedCart,
} from 'features/Cart/cartSlice';
import {
  orderDetailsSelector,
  updateWalletDiscount,
  setBuyerInOrderDetails,
} from 'features/Orders/orderDetailsSlice';
import { calculateCart } from 'utils/cartCalculations';
import { useEffect } from 'react';
import specialOfferSlice from 'features/SpecialOffers/specialOffersSlice';
import { setBuyingUser } from 'features/User/UserSlice';

type Props = {
  children: React.ReactNode;
  userSessionData: UserSessionData;
  configData: IConfig;
  loggedUser: any;
};

function Shop({ children, userSessionData }: Props) {
  const cart = useSelector(cartSelector);
  const configSlice = useSelector(configSelector);
  const userSlice = useSelector(userSelector);
  const orderDetailsSlice = useSelector(orderDetailsSelector);
  const orderDetails = orderDetailsSlice.orderDetails;
  const { useCheckSpecialOffersBasedOnCartMutation } = specialOfferSlice;
  const [checkSpecialOffersBasedOnCart] = useCheckSpecialOffersBasedOnCartMutation();
  const dispatch = useDispatch();
  const fetchedActiveSpecialOffers = cart.fetchedActiveSpecialOffers;

  useEffect(() => {
    if (!userSlice.buyingUser && userSlice.loggedUser) {
      dispatch(setBuyerInOrderDetails({ ...userSlice.loggedUser }));
      dispatch(setBuyingUser({ ...(userSlice.loggedUser as unknown as any) }));
    }
  }, [userSlice.loggedUser, userSlice.buyingUser]);

  useEffect(() => {
    const calculatedCart = configSlice.config
      ? calculateCart({
          cart,
          configData: configSlice.config,
          buyingUser: userSlice.buyingUser?.id ? userSlice.buyingUser : userSlice.loggedUser,
          orderDetails: orderDetails,
          withSpecialOffers: true,
        })
      : null;

    dispatch(setCalculatedCart(calculatedCart));
  }, [
    cart.cartActiveSpecialOffers,
    cart.cartItems,
    cart.cartFreeItems,
    cart.voucherProducts,
    cart.discountString,
    cart.discountProductString,
    cart.fetchedActiveSpecialOffers,
    configSlice.config,
    userSlice.buyingUser,
    orderDetails,
  ]);

  useEffect(() => {
    if (cart.calculatedCart?.virtualWalletDiscount != null) {
      const normalizedCartWalletDiscount = cart.calculatedCart.virtualWalletDiscount;
      const normalizedDetailsWalletDiscount = orderDetails?.walletDiscount * 100;

      if (normalizedCartWalletDiscount && normalizedDetailsWalletDiscount && normalizedCartWalletDiscount != normalizedDetailsWalletDiscount) {
        dispatch(updateWalletDiscount(normalizedCartWalletDiscount / 100));
      }
    }
  }, [cart.calculatedCart]);

  useEffect(() => {
    const user = userSlice.buyingUser
      ? userSlice.buyingUser.id
      : orderDetails?.buyer?.id
      ? orderDetails?.buyer.id
      : userSessionData?.sub || null;

    const cartDTO = user
      ? {
          ...cart,
          user,
        }
      : { ...cart };

    checkSpecialOffersBasedOnCart(cartDTO).then((result: any) => {
      const data = result.data;

      if (result.data) {
        if (
          (!cart.cartActiveSpecialOffers && data) ||
          (cart.cartActiveSpecialOffers?.length === 0 && data && data.length > 0)
        ) {
          dispatch(refreshFetchedActiveSpecialOffers(data));
          dispatch(refreshCartActiveSpecialOffers(data));
        }

        const activeOffersRewardsIds = [];
        const dataRewardsIds = [];

        result.data.forEach((offer) => {
          offer.rewards.forEach((reward) => {
            dataRewardsIds.push(reward.data.id);
          });
        });
        cart.cartActiveSpecialOffers.forEach((offer) => {
          offer.rewards.forEach((reward) => {
            activeOffersRewardsIds.push(reward.data.id);
          });
        });

        if (activeOffersRewardsIds.sort().join(',') !== dataRewardsIds.sort().join(',')) {
          dispatch(refreshFetchedActiveSpecialOffers(data));
          dispatch(refreshCartActiveSpecialOffers(data));
        }
      }
    });
  }, [
    cart,
    orderDetails,
    userSlice.buyingUser,
    checkSpecialOffersBasedOnCart,
    dispatch,
    fetchedActiveSpecialOffers,
    userSessionData?.sub,
  ]);

  return <>{children}</>;
}

export default Shop;
