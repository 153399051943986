import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {ICategory} from 'features/Categories/catogoriesSlice';
import {IFilter} from '../types/filters';
import {ISearch} from '../types/search';
import {Paginated} from '../types/paginatedQuery';
import {baseBackendUrl} from 'utils/config';
import {RootState} from 'app/redux/store';
import {IFile} from 'features/Files/filesSlice';

export interface IMlmRole {
    id: number;
    label: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IPromoPrice {
    value: number;
    // pointsAmount: number;
    roles: IMlmRole[];
    period: {
        from: Date;
        to: Date;
    };
}

export enum ProductType {
    STANDARD = 'STANDARD',
    MARKETING = 'MARKETING',
}

export interface IProduct {
    id?: number;
    name: string;
    sku: string;
    description: string;
    features: string;
    ingredients: string;
    application: string;
    categories: ICategory[];
    gallery: IFile[];
    type: ProductType;
    activeFrom: Date;
    activeTo: Date;
    grossPrice: number;
    catalogPrice: number;
    specialOfferPrice: number;
    //pointsAmount: number;
    promoPrices: IPromoPrice[];
    promoPrice: number;
    available: number;
    noPoints: boolean;
    active: boolean;
    //starter: boolean;
    new: boolean;
    untilEndOfStock: boolean;
    createdAt: Date;
    updatedAt: Date;
    quantity?: number;
}

export type IShopProduct = IProduct & {
    catalogPrice: number;
    userPrice: number;
    promoPrice: number | null;
};

export const productsApi = createApi({
    reducerPath: 'products',
    tagTypes: ['Products', 'Product'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseBackendUrl}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).userSlice?.userSessionData?.token;

            /* TODO: figure out why token is not provided on the first request */

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllProducts: builder.query<Paginated<IProduct>, void>({
            query: () => 'products',
            providesTags: (result) =>
                result
                    ? [
                        ...result.data.map(({id}) => ({type: 'Products' as const, id})),
                        {type: 'Products', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Products', id: 'PARTIAL-LIST'}],
        }),
        getAllAdmin: builder.query<Paginated<IProduct>, IFilter>({
            query: (filters) =>
                `products/all-for-admin?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.data.map(({id}) => ({type: 'Product' as const, id})),
                        {type: 'Product', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Product', id: 'PARTIAL-LIST'}],

            keepUnusedDataFor: 0,
        }),
        getAllAdminAsList: builder.query<IProduct[], void>({
            query: () => `products/admin-list`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'Product' as const, id})),
                        {type: 'Product', id: 'ADMIN-LIST'},
                    ]
                    : [{type: 'Product', id: 'ADMIN-LIST'}],
        }),
        getAllUserAsList: builder.query<IProduct[], void>({
            query: () => `products/user-list`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'Product' as const, id})),
                        {type: 'Product', id: 'USER-LIST'},
                    ]
                    : [{type: 'Product', id: 'USER-LIST'}],
        }),
        findProducts: builder.query<Paginated<IProduct>, ISearch>({
            query: (search) =>
                `products/search?order=ASC&offset=0&limit=10&search[phrase][value]=${
                    search.phrase
                }&search[categories][value]=[${[...search.categories]}]`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.data.map(({id}) => ({type: 'Products' as const, id})),
                        {type: 'Products', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Products', id: 'PARTIAL-LIST'}],
        }),
        getProductAdmin: builder.query<IProduct, number>({
            query: (id) => `products/admin/${id}`,
            providesTags: (result) => (result ? [{type: 'Product', id: result.id}] : []),
        }),
        getProduct: builder.query<IProduct, number>({
            query: (id) => `products/${id}`,
            providesTags: (result) => (result ? [{type: 'Product', id: result.id}] : []),
        }),
        addProduct: builder.mutation<any, any>({
            query(body) {
                return {
                    url: `products`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result, error, body) => {
                return [
                    {type: 'Product', body},
                    {type: 'Products', body: 'PARTIAL-LIST'},
                ];
            },
        }),
        editProduct: builder.mutation<IProduct, any>({
            query({id, formData}) {
                return {
                    url: `products/${id}`,
                    method: 'PUT',
                    body: formData,
                };
            },
            invalidatesTags: (result, error, body) => {
                return [
                    {type: 'Product', body},
                    {type: 'Products', body: 'PARTIAL-LIST'},
                ];
            },
        }),
        syncStocks: builder.query<Paginated<IProduct>, void>({
            query: () => 'products/stocks/sync'

        }),
        deleteManyProducts: builder.mutation<IProduct[], number[]>({
            query(body) {
                return {
                    url: `products/`,
                    method: 'DELETE',
                    body,
                };
            },
            invalidatesTags: (result, error, body) => {
                return [
                    {type: 'Product', body},
                    {type: 'Products', body: 'PARTIAL-LIST'},
                ];
            },
        }),
        deleteOneProduct: builder.mutation<IProduct, number>({
            query(id) {
                return {
                    url: `products/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, id) => {
                return [
                    {type: 'Product', id},
                    {type: 'Products', id: 'PARTIAL-LIST'},
                ];
            },
        }),
    }),
});

export default productsApi;
