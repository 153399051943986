import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IProduct } from 'features/Products/productsSlice';
import { IFilter } from 'features/types/filters';
import { baseBackendUrl } from 'utils/config';
import { IMlmRole } from '../Roles/rolesSlice';
import { Paginated } from '../types/paginatedQuery';
import { RootState } from 'app/redux/store';
import { ICategory } from 'features/Categories/catogoriesSlice';
import { SpecialOfferOutDTO, SpecialOfferInDTO } from 'utils/types/special-offers';
import {IUser} from "../User/UserSlice";
import {IOrder} from "../Orders/orderDetailsSlice";

export interface IUserPickData {
  product?: IProduct;
  products?: IProduct[];
  qty?: number;
}

export enum DiscountType {
  PROMO = 'promo',
  DISCOUNT = 'discount',
}

export enum ValueType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
}

export enum CouponValueType {
  VALUE = 'value',
  PRODUCTS = 'products',
}

export enum ConditionType {
  USER_CONDITION = 'UserCondition',
  AMOUNT = 'Amount',
  AMOUNT_IN_PERIOD = 'AmountInPeriod',
  USER_REGISTER_PERIOD = 'UserRegisterPeriod',
  USER_WITH_STATUS = 'UserWithStatus',
  USER_STATUS_IN_PERIOD = 'UserStatusInPeriod',
  ORDERS_IN_WEEKS = 'OrdersInWeeks',
  ORDERS_COUNT_IN_TIME_PERIOD = 'OrdersCountInTimePeriod',
  ORDER_PRODUCT_COUNT = 'OrderProductCount',
  ORDERS_COUNT = 'OrdersCount',
  ORDER_WITH_PRODUCT = 'OrderWithProduct',
  PRODUCT = 'Product',
  MONTHLY_ORDERS_MINIMAL_VALUE = 'MonthlyOrdersMinimalValue',
  MONTHS_FROM_FIRST_ORDER = 'MONTHS_FROM_FIRST_ORDER',
  PROMOTION = 'Promotion',
  ORDERS_COUNT_DAYS_FROM_STATUS = 'OrdersCountDaysFromStatus',
  TIME_FROM_STATUS = 'TimeFromStatus',
  RECRUITER = 'Recruiter',
}

export enum RewardType {
  FREE_PRODUCT = 'FreeProduct',
  PRODUCT_SPECIAL_PRICE = 'ProductSpecialPrice',
  DISCOUNT_PRODUCT = 'DiscountProduct',
  EXTRA_PRODUCT = 'ExtraProduct',
  DISCOUNT_ORDER = 'DiscountOrder',
  DISCOUNT_SHIPPING = 'DiscountShipping',
  COUPON = 'Coupon',
  PRODUCT_TURNOVER = 'ProductTurnover',
  PRODUCT_HALF_PRICE = 'ProductHalfPrice',
  MONTHS_NUMBER = 'MonthsNumber',
  DAYS_NUMBER = 'DaysNumber',
  PRODUCTS_FROM_CATEGORY = 'ProductsFromCategory',
}

export enum UserSpecialOfferRewardType {
  CURRENT_ORDER = 'CURRENT_ORDER',
  LATER_USE = 'LATER_USE',
}

export enum RewardDisplayType {
  PRODUCT = 'PRODUCT',
  PRODUCTS = 'PRODUCTS',
  DESCRIPTION = 'DESCRIPTION',
}

export const rewardsAvailableVariants = [
  {
    type: RewardType.FREE_PRODUCT,
    name: 'Produkt jako gratis',
  },
  {
    type: RewardType.PRODUCT_SPECIAL_PRICE,
    name: 'Ustalona cena produktu',
  },
  {
    type: RewardType.DISCOUNT_PRODUCT,
    name: 'Rabat procentowy na produkt',
  },
  {
    type: RewardType.EXTRA_PRODUCT,
    name: 'Produkt dodawany do koszyka w zależności od jego zawartości',
  },
  {
    type: RewardType.DISCOUNT_ORDER,
    name: 'Rabat procentowy na całe zamówienie (bez kosztu wysyłki)',
  },
  {
    type: RewardType.DISCOUNT_SHIPPING,
    name: 'Koszt wysyłki lub rabat',
  },
  {
    type: RewardType.COUPON,
    name: 'Voucher produktowy lub kwotowy',
  },
  {
    type: RewardType.PRODUCT_TURNOVER,
    name: 'Produkt za obrót',
  },
  {
    type: RewardType.PRODUCT_HALF_PRICE,
    name: 'Dowolny produkt za pół ceny',
  },
  {
    type: RewardType.MONTHS_NUMBER,
    name: 'Liczba miesięcy następujących po spełnieniu warunku z określoną ceną wysyłki',
  },
  {
    type: RewardType.DAYS_NUMBER,
    name: 'Liczba dni następujących po spełnieniu warunku z określoną ceną wysyłki',
  },

  {
    type: RewardType.PRODUCTS_FROM_CATEGORY,
    name: 'Liczba produktów z danej kategorii w określonej cenie',
  },
] as const;

export interface IReward {
  id?: number;
  //type?: typeof rewardsAvailableVariants[number]['type'];
  type?: RewardType;
  isChosen?: boolean;
  isUsed?: boolean;
  userPickData?: IUserPickData;
  qty?: number;
  value?: number;
  valueType?: ValueType | DiscountType | CouponValueType;
  valueDiscount?: number;
  discount?: number;
  minOrderValue?: number;
  minValue?: number;
  code?: string;
  usedNumberOfTimes?: number;
  daysQty?: number;
  monthsQty?: number;
  minQty?: number;
  maxQty?: number;
  shippingCostValue?: number;
  maxQtyOfRepeats?: number;
  product?: Partial<IProduct>;
  productInCart?: Partial<IProduct>;
  productToBeAddedToCart?: Partial<IProduct>;
  productWithDiscount?: Partial<IProduct>;
  productWithSpecialPrice?: Partial<IProduct>;
  productsCategory?: ICategory;
  maxDiscountValueOfPriceValue?: number;
  multipleUsage?: boolean;
  period?: {
    from: Date;
    to: Date;
  };
  data?: Omit<IReward, 'type' | 'data'>;
}

export const conditionsAvailableVariants = [
  {
    type: ConditionType.AMOUNT,
    name: 'Wartość bieżacego zamówienia',
  },
  {
    type: ConditionType.AMOUNT_IN_PERIOD,
    name: 'Wartość zamówień w okresie',
  },
  {
    type: ConditionType.ORDERS_COUNT_IN_TIME_PERIOD,
    name: 'Liczba zamówień w okresie',
  },
  {
    type: ConditionType.ORDERS_COUNT_DAYS_FROM_STATUS,
    name: 'Numer zamówienia w określonej liczbie dni od osiągnięcia określonego statusu',
  },
  {
    type: ConditionType.ORDERS_COUNT,
    name: 'Liczba zamówień',
  },
  {
    type: ConditionType.USER_CONDITION,
    name: 'Użytkownik',
  },
  {
    type: ConditionType.USER_STATUS_IN_PERIOD,
    name: 'Użytkownik utrzymał status',
  },
  {
    type: ConditionType.ORDER_WITH_PRODUCT,
    name: 'Zamówienie zawiera produkt (bez nagród)',
  },
  {
    type: ConditionType.ORDER_PRODUCT_COUNT,
    name: 'Liczba produktów w zamówieniu (bez nagród)',
  },
  {
    type: ConditionType.MONTHLY_ORDERS_MINIMAL_VALUE,
    name: 'Minimalna wartość katalogowa zamówień w danym miesiącu',
  },
  {
    type: ConditionType.TIME_FROM_STATUS,
    name: 'Liczba miesięcy od uzyskania statusu',
  },
  {
    type: ConditionType.PROMOTION,
    name: 'Skorzystanie z promocji',
  },
  {
    type: ConditionType.RECRUITER,
    name: 'Prezent za bezpośrednio zrekrutowanego Konsultanta z opłaconym zamówieniem',
  },
] as const;

export interface ICondition {
  id?: number;
  //type?: typeof conditionsAvailableVariants[number]['type'];
  type?: ConditionType;
  valueType?: ConditionAmountType;
  operator?: Operators;
  countOperator?: Operators;
  valueOperator?: Operators;
  value?: number;
  period?: {
    from: Date;
    to: Date;
  };
  weeks?: number;
  days?: number;
  count?: number;
  ordersTimePeriodVariant?: ConditionPeriodType;
  product?: Partial<IProduct>;
  variant?: 'is' | 'registrationDate' | 'hasStatus' | 'fromStructureOf';
  orderOwnership?: ConditionOrderOwnership;
  orderType?: ConditionOrderType;
  getUserBy?: 'byName' | 'byId';
  users?: number[] | any[];
  usersString?: string;
  usersArray?: any[];
  parentUsers?: number[];
  parentUsersString?: string;
  parentUsersArray?: any[];
  role?: IMlmRole;
  roles?: IMlmRole[];
  // promotion?: ISpecialOffer;
  data?: Omit<ICondition, 'type' | 'id' | 'data'>;
}

export interface ISpecialOffer {
  id: number;
  name: string;
  active: boolean;
  description: string;
  position: number;
  combineWithOthers: boolean;
  rewardChooseType: RewardChooseType;
  conditions: ICondition[];
  rewards: IReward[];
  chosenRewardsInfo?: IReward[];
  start: Date;
  end: Date;
  noPickRewardActionSpecialOffer?: boolean;
  allRewardTypesInThisSpecialOffer?: Partial<
    typeof rewardsAvailableVariants[number]['type'][]
  >;
}

export interface IUsersSpecialOffer {
  id: number;
  user: IUser;
  specialOffer: ISpecialOffer;
  order: IOrder;
  rewards?: IUsersSpecialOfferReward[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IUsersSpecialOfferReward {
  id: number;
  userSpecialOffer: IUsersSpecialOffer;
  reward: any;
  start?: Date;
  end?: Date;
  voucherProducts?: IProduct[];
  usedTimes: number;
  available: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum RewardChooseType {
  ALL = 'ALL',
  ALL_OR_NONE = 'ALL_OR_NONE',
  ONE = 'ONE',
  ANY = 'ANY',
}

export enum Operators {
  lt = 'lt',
  gt = 'gt',
  gte = 'gte',
  lte = 'lte',
  eq = 'eq',
}

export enum ConditionAmountType {
  CATALOGUE = 'CATALOGUE',
  TO_PAY = 'TO_PAY',
}

export enum ConditionOrderOwnership {
  OWN = 'OWN',
  STRUCTURE = 'STRUCTURE',
  ALL = 'ALL',
}

export enum ConditionOrderType {
  PAYED = 'PAYED',
  PLACED = 'PLACED',
}

export enum ConditionPeriodType {
  DATES = 'DATES',
  WEEKS = 'WEEKS',
}

const specialOffersSlice = createApi({
  reducerPath: 'specialOffers',
  baseQuery: fetchBaseQuery({
    baseUrl: baseBackendUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['SpecialOffer'],
  endpoints: (builder) => ({
    getAllSpecialOffers: builder.query<Paginated<SpecialOfferOutDTO>, void>({
      query: () => 'special-offers',
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'SpecialOffer', id })) : [],
    }),
    getFilteredSpecialOffers: builder.query<Paginated<SpecialOfferOutDTO>, IFilter>({
      query: (filters) =>
        `special-offers?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'SpecialOffer' as const, id })),
              { type: 'SpecialOffer', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'SpecialOffer', id: 'PARTIAL-LIST' }],
    }),
    getSpecialOffer: builder.query<SpecialOfferOutDTO, number>({
      query: (id) => `special-offers/${id}`,
      providesTags: (result) => (result ? [{ type: 'SpecialOffer', id: result.id }] : []),
    }),
    addSpecialOffer: builder.mutation<SpecialOfferInDTO, Omit<SpecialOfferInDTO, 'id'>>({
      query(body) {
        return {
          url: `special-offers`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['SpecialOffer'],
    }),
    editSpecialOffer: builder.mutation<SpecialOfferInDTO, Pick<SpecialOfferOutDTO, 'id'>>(
      {
        query({ id, ...rest }) {
          return {
            url: `special-offers/${id}`,
            method: 'PUT',
            body: rest,
          };
        },
        invalidatesTags: ['SpecialOffer'],
      }
    ),
    deleteManySpecialOffers: builder.mutation<SpecialOfferInDTO[], number[]>({
      query(body) {
        return {
          url: `special-offers/`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'SpecialOffer', body },
          { type: 'SpecialOffer', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteOneSpecialOffer: builder.mutation<SpecialOfferInDTO, number>({
      query(id) {
        return {
          url: `special-offers/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'SpecialOffer', id },
          { type: 'SpecialOffer', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    checkSpecialOffersBasedOnCart: builder.mutation<SpecialOfferInDTO[], any>({
      query(body) {
        return {
          url: `special-offers/check`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['SpecialOffer'],
    }),
  }),
});

export default specialOffersSlice;
