import {mdiCartVariant} from '@mdi/js';
import Icon from '@mdi/react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NoSsr from '@mui/material/NoSsr';
import {styled as styledMUI} from '@mui/material/styles';
import {cartSelector} from 'features/Cart/cartSlice';
import Link from 'next/link';
import {FC} from 'react';
import {useSelector} from 'react-redux';
import {Typography} from "@mui/material";
import {moneyFormat} from "../../../utils/helpers";
import {ProductType} from "../../../features/Products/productsSlice";

const StyledBadge = styledMUI(Badge)(({theme}) => ({
  '& .MuiBadge-badge': {
    backgroundColor: `${theme.customColors.primaryBrand}`,
  },
}));

const CartMenuItem: FC = () => {
  const cart = useSelector(cartSelector);
  const cartItems = cart.cartItems.filter((item) => item.product.type === ProductType.STANDARD);

  const sum = cartItems.reduce(
    (sum, item) => sum + ((item.product.promoPrice ? item.product.promoPrice : item.product.catalogPrice) * item.quantity),
    0
  );

  const quantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <Link href="/sklep/koszyk" passHref>
      <IconButton aria-label="cart">
        <NoSsr>
          <div className={'flex'}>
            <Typography
              variant={'subtitle1'}
              sx={{color: 'black', mr: 1}}>
              Wartość katalogowa zamówienia
            </Typography>
            <Typography
              variant={'subtitle1'}
              sx={{fontWeight: 'bold', color: 'black', mr: 3}}>
              {moneyFormat(sum)} zł
            </Typography>
          </div>
          <StyledBadge
            badgeContent={quantity}
            color="secondary"
          >
            <Icon path={mdiCartVariant} size={1.25}/>
          </StyledBadge>
        </NoSsr>
      </IconButton>
    </Link>
  );
};

export default CartMenuItem;
