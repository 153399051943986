import {
  CalculatedCart,
  CalculatedCartSpecialOffer,
  CartItemsState,
  ICartDTO,
  ICartItem,
} from 'features/Cart/cartSlice';
import {IConfig} from 'features/Config/configSlice';
import {IProduct, ProductType} from 'features/Products/productsSlice';
import {IUser} from 'features/User/UserSlice';

import {IOrderDetails, PaymentType, ShippingType,} from 'features/Orders/orderDetailsSlice';
import {
  CouponValueType,
  DiscountType,
  IReward,
  RewardDisplayType,
  RewardType,
  UserSpecialOfferRewardType,
} from 'features/SpecialOffers/specialOffersSlice';
import {getRewardName} from 'components/SpecialOfferFormFields/rewards/RepeaterReward';

export const productPrices = (
    product: IProduct,
    user: IUser,
    configData: IConfig,
    discountPerMoneyUnit?: number,
    cart?: CartItemsState
) => {
  let promoPrice = null;
  // Config data comes form the REDUX store, so it is not available while SSR
  if (!configData)
    return {
      price: product.grossPrice,
      promoPrice: product.promoPrice,
      catalogPrice: product.grossPrice,
      userPrice: product.grossPrice,
    };
  if (product.promoPrices) {
    const filteredPromoPrices = product.promoPrices;

    const foundPromoPrice = filteredPromoPrices.find((price) => {
      return price.roles.find((role) => {
        return role.id === user?.mlmRoleStable.id || (!user && role.label === 'guest');
      });
    });

    if (foundPromoPrice) {
      promoPrice = foundPromoPrice.value;
    }
  }


  const baseUserPrice = promoPrice ? promoPrice : product.grossPrice;

  const orderTotal = cart?.cartItems
    .filter((item) => item.product.type === ProductType.STANDARD)
    .reduce((total, item: ICartItem) => {
      const price = item.product.promoPrice ? item.product.promoPrice : item.product.catalogPrice;
      return total + (price / 100 * item.quantity)
    }, 0);

  const discount = user?.mlmRoleStable?.discount
    + (orderTotal > 400 ? user?.mlmRoleStable?.additionalDiscount + cart?.discountFromPreviousMonth : 0);
  let userPrice =
      product.type === ProductType.MARKETING
          ? product.grossPrice
          : (baseUserPrice * ((100 - (isNaN(discount) ? 0 : discount)) / 100));

  if (discountPerMoneyUnit) {
    userPrice = userPrice * (1 - discountPerMoneyUnit);
  }

  return {
    catalogPrice: Math.round(product.grossPrice),
    userPrice: Math.round(userPrice),
    promoPrice: Math.round(promoPrice),
  };
};

export const formatPrice = (price: number) => {
  const newPrice = (price / 100);
  return (newPrice ?? 0).toFixed(2).replace('.', ',');
};

export const calculateCartItem = (
    item: ICartItem,
    buyingUser: IUser,
    configData: IConfig,
    discountPerMoneyUnit?: number,
    cart?: CartItemsState
) => {
  const prices = productPrices(
      item.product,
      buyingUser,
      configData,
      discountPerMoneyUnit,
      cart
  );

  const catalogValue = (prices.promoPrice ? prices.promoPrice : prices.catalogPrice) * item.quantity;
  const catalogPrice = prices.promoPrice ? prices.promoPrice : prices.catalogPrice;

  return {
    ...item,
    catalogValue,
    catalogPrice,
    promoPrice: Math.round(prices.promoPrice),
    userPrice: Math.round(prices.userPrice),
    userValue: Math.round(prices.userPrice * item.quantity),
    catalogPriceStr: formatPrice(catalogPrice),
    userPriceStr: formatPrice(prices.userPrice),
    catalogValueStr: formatPrice(catalogValue),
    userValueStr: formatPrice(prices.userPrice * item.quantity),
  };
};

export const calculateCart = ({
                                cart,
                                configData,
                                buyingUser,
                                orderDetails,
                                withSpecialOffers,
                              }: {
  cart: CartItemsState;
  configData: IConfig;
  buyingUser: IUser;
  orderDetails: IOrderDetails;
  withSpecialOffers: boolean;
}): CalculatedCart => {
  // TODO - from orderDetails
  let baseShippingCost = calculateBaseShippingCost(orderDetails, configData);

  let finalShippingCost = null;
  let finalShippingDiscount = 0;
  let totalOrderDiscountPercentage = 0;
  let totalForConditions = 0;

  // filter standard and marketing products

  const standardProductsBeforeDiscount = cart.cartItems
      .filter((item) => {
        return item.product.type === ProductType.STANDARD;
      })
      .map((item) => {
        return calculateCartItem(item, buyingUser, configData, 0, cart);
      });

  const marketingProductsBeforeDiscount = cart.cartItems
      .filter((item) => {
        return item.product.type === ProductType.MARKETING;
      })
      .map((item) => {
        return calculateCartItem(item, buyingUser, configData, 0, cart);
      });

  const freeProductsBeforeDiscount = cart.cartFreeItems?.map((item) => {
        return calculateCartItem(item, buyingUser, configData, 0, cart);
      });

  const voucherProducts = cart.voucherProducts?.map((item) => {
        return calculateCartItem(item, buyingUser, configData, 0, cart);
      });

  // calculate catalog values

  const standardProductsCatalogValue = standardProductsBeforeDiscount.reduce(
      (total, product) => {
        return total + product.catalogValue;
      },
      0
  );

  const marketingProductsCatalogValue = [
      ...marketingProductsBeforeDiscount,
      ...freeProductsBeforeDiscount,
      ...voucherProducts,
  ].reduce(
      (total, product) => {
        return total + product.catalogValue;
      },
      0
  );

  const standardProductsUserValueBeforeDiscount = standardProductsBeforeDiscount.reduce(
      (total, product) => {
        return total + product.userValue;
      },
      0
  );

  const marketingProductsUserValueBeforeDiscount = [
    ...marketingProductsBeforeDiscount,
    ...freeProductsBeforeDiscount,
    ...voucherProducts,
  ].reduce(
      (total, product) => {
        return total + product.userValue;
      },
      0
  );

  // sum of user prices for standard and marketing products - basis for total discount calculation
  const totalUserValueBeforeDiscount =
      standardProductsUserValueBeforeDiscount + marketingProductsUserValueBeforeDiscount;

  // find special offers with discounts for order and shipping

  const specialOffersWithDiscounts = [];

  cart.cartActiveSpecialOffers.forEach((offer) => {
    const chosenRewards = offer.rewards.filter((reward) => {
      return (
          reward.isChosen &&
          [RewardType.DISCOUNT_ORDER, RewardType.DISCOUNT_SHIPPING].includes(reward.type)
      );
    });

    if (chosenRewards.length > 0) {
      specialOffersWithDiscounts.push({ ...offer, chosenRewards });
    }
  });

  specialOffersWithDiscounts.forEach((offer) => {
    offer.chosenRewards.forEach((chosenReward) => {
      if (chosenReward.type === RewardType.DISCOUNT_ORDER) {
        totalOrderDiscountPercentage += chosenReward.data.value;
      }

      if (chosenReward.type === RewardType.DISCOUNT_SHIPPING) {
        if (chosenReward.data.valueType === DiscountType.DISCOUNT) {
          finalShippingDiscount += chosenReward.data.value;
        }

        if (chosenReward.data.valueType === DiscountType.PROMO) {
          finalShippingCost = chosenReward.data.value;
        }
      }
    });
  });

  // discount in PLN per 1 PLN
  const discountPerMoneyUnit = totalOrderDiscountPercentage / 100;

  const standardProductsAfterDiscount = cart.cartItems
      .filter((item) => {
        return item.product.type === ProductType.STANDARD;
      })
      .map((item) => {
        return calculateCartItem(item, buyingUser, configData, discountPerMoneyUnit, cart);
      });

  const marketingProductsAfterDiscount = cart.cartItems
      .filter((item) => {
        return item.product.type === ProductType.MARKETING;
      })
      .map((item) => {
        return calculateCartItem(item, buyingUser, configData, discountPerMoneyUnit, cart);
      });

  finalShippingCost =
      finalShippingCost !== null
          ? finalShippingCost
          : (baseShippingCost * (100 - finalShippingDiscount)) / 100;

  // TOTALS

  const standardProductsUserValue = standardProductsAfterDiscount.reduce(
      (total, product) => {
        return total + product.userValue;
      },
      0
  );

  const marketingProductsUserValue = marketingProductsAfterDiscount.reduce(
      (total, product) => {
        return total + product.catalogValue;
      },
      0
  );

  let rewardsCatalogValue = 0;
  let rewardsUserValue = 0;

  const marketingAndStandardProductsCatalogValue = marketingProductsCatalogValue + standardProductsCatalogValue;
  const marketingAndStandardProductsUserValue = marketingProductsUserValue + standardProductsUserValue;
  const shippingCost = finalShippingCost;

  // offers, for which any rewards have been chosen
  const calculatedSpecialOffers: CalculatedCartSpecialOffer[] = [];

  if (cart.cartActiveSpecialOffers) {
    cart.cartActiveSpecialOffers.forEach((offer) => {
      const chosenRewards = offer.rewards
          .filter((reward) => {
            return reward.isChosen;
          })
          .map((reward) => {
            const calculatedReward = calculateReward(reward, {
              allProducts: [
                ...standardProductsAfterDiscount,
                ...marketingProductsAfterDiscount,
              ],
              standardProductsCatalogValue,
            });

            if (
                calculatedReward?.type === RewardDisplayType.PRODUCT &&
                'catalogValue' in calculatedReward.data
            ) {
              rewardsCatalogValue += calculatedReward.data.catalogValue;
              rewardsUserValue += calculatedReward.data.userValue;
            }

            if (
                calculatedReward?.type === RewardDisplayType.PRODUCTS &&
                Array.isArray(calculatedReward.data)
            ) {
              calculatedReward.data.forEach((reward) => {
                rewardsCatalogValue += reward.catalogValue;
                rewardsUserValue += reward.userValue;
              });
            }

            return { reward, calculated: calculatedReward };
          })
          .filter((reward) => reward.calculated);

      if (chosenRewards.length > 0) {
        chosenRewards.sort((r1, r2) => {
          if (
              (r1.calculated.type === RewardDisplayType.PRODUCT ||
                  r1.calculated.type === RewardDisplayType.PRODUCTS) &&
              r2.calculated.type === RewardDisplayType.DESCRIPTION
          ) {
            return -1;
          }

          return 1;
        });

        calculatedSpecialOffers.push({ offer: offer, chosenRewards: chosenRewards });
      }
    });
  }

  const specialOffersReverseSorted = [...calculatedSpecialOffers].sort((so1, so2) => {
    if (so1.offer.position > so2.offer.position) {
      return 1;
    }
    if (so1.offer.position < so2.offer.position) {
      return -1;
    }
    return 0;
  });

  const allProductsCatalogValue = marketingAndStandardProductsCatalogValue + rewardsCatalogValue;
  const allProductsUserValue = marketingAndStandardProductsUserValue + rewardsUserValue;

  const total = allProductsUserValue + shippingCost;

  const maxVirtualWalletDiscount = Math.floor(
      (total * configData.virtualWalletMaxDiscount) / 100
  );

  const rawVirtualWalletDiscount = orderDetails?.walletDiscount;

  let payment = total;
  let virtualWalletDiscount = 0;

  if (!isNaN(rawVirtualWalletDiscount)) {
    if (rawVirtualWalletDiscount * 100 > maxVirtualWalletDiscount) {
      virtualWalletDiscount = maxVirtualWalletDiscount;
    } else {
      virtualWalletDiscount = rawVirtualWalletDiscount * 100;
    }

    payment = total - virtualWalletDiscount;

    //payment=(total-rawVirtualWalletDiscount*100) > 0 ?
  }

  if (cart.discountStringValue) {
    payment = (payment - cart.discountStringValue) > 0 ? payment - cart.discountStringValue : 0;
  }

  const calculatedCart = {
    standardProducts: standardProductsAfterDiscount,
    marketingProducts: marketingProductsAfterDiscount,
    specialOffers: calculatedSpecialOffers,
    freeProducts: freeProductsBeforeDiscount,
    voucherProducts: voucherProducts,
    standardProductsCatalogValue,
    standardProductsCatalogValueStr: formatPrice(standardProductsCatalogValue),
    standardProductsUserValue,
    standardProductsUserValueStr: formatPrice(standardProductsUserValue),
    marketingProductsCatalogValue,
    marketingProductsCatalogValueStr: formatPrice(marketingProductsCatalogValue),
    marketingProductsUserValue,
    marketingProductsUserValueStr: formatPrice(marketingProductsUserValue),
    marketingAndStandardProductsCatalogValue,
    marketingAndStandardProductsCatalogValueStr: formatPrice(
        marketingAndStandardProductsCatalogValue
    ),
    marketingAndStandardProductsUserValue,
    marketingAndStandardProductsUserValueStr: formatPrice(
        marketingAndStandardProductsUserValue
    ),
    rewardsCatalogValue,
    rewardsCatalogValueStr: formatPrice(rewardsCatalogValue),
    rewardsUserValue,
    rewardsUserValueStr: formatPrice(rewardsUserValue),
    allProductsCatalogValue,
    allProductsCatalogValueStr: formatPrice(allProductsCatalogValue),
    allProductsUserValue,
    allProductsUserValueStr: formatPrice(allProductsUserValue),
    shippingCost,
    shippingCostStr: formatPrice(shippingCost),
    total,
    totalStr: formatPrice(total),
    totalForConditions,
    maxVirtualWalletDiscount,
    virtualWalletDiscount,
    virtualWalletDiscountStr: formatPrice(virtualWalletDiscount),
    payment,
    paymentStr: formatPrice(payment),
    discountCode: cart.discountString,
    discountValue: cart.discountStringValue,
    discountProductCode: cart.discountProductString,
    discountProductValue: cart.discountProductValue,
    discountStringValue: formatPrice(cart.discountStringValue),
    discountType: 'AMOUNT'
  };

  console.log(calculatedCart);

  return calculatedCart;
};

export function calculateBaseShippingCost(
    orderDetails: IOrderDetails,
    configData: IConfig
) {
  let shippingCost = 0;

  if (orderDetails?.shippingType === ShippingType.DPD) {
    shippingCost = configData.dpdShippingCost;
  }

  if (orderDetails?.shippingType === ShippingType.INPOST) {
    shippingCost = configData.inpostShippingCost;
  }

  if (orderDetails?.shippingType === ShippingType.LOCAL_PICKUP) {
    shippingCost = 0;
  }

  if (orderDetails?.paymentType === PaymentType.COD) {
    shippingCost += configData.dpdCODCost;
  }

  return shippingCost;
}

export function calculateReward(
    reward: IReward,
    cartData: { allProducts: any[]; standardProductsCatalogValue: number }
) {
  if (
      [
        RewardType.DISCOUNT_ORDER,
        RewardType.DISCOUNT_SHIPPING,
        RewardType.COUPON,
        RewardType.DAYS_NUMBER,
        RewardType.MONTHS_NUMBER,
      ].includes(reward.type)
  ) {
    const type = RewardDisplayType.DESCRIPTION;

    const title = formatRewardSummary(reward);
    return {
      type,
      data: {
        title,
      },
    };
  }
  if ([RewardType.FREE_PRODUCT].includes(reward.type)) {
    const product = {
      name: reward.data.product.name,
      sku: reward.data.product.sku,
      id: reward.data.product.id,
    };

    const type = RewardDisplayType.PRODUCT;

    return {
      type,
      data: {
        product,
        quantity: reward.data.qty,
        catalogPrice: reward.data.product.grossPrice,
        userPrice: 0,
        catalogPriceStr: formatPrice(reward.data.product.grossPrice),
        userPriceStr: formatPrice(0),
        catalogValue: reward.data.product.grossPrice * reward.data.qty,
        userValue: 0,
        catalogValueStr: formatPrice(reward.data.product.grossPrice * reward.data.qty),
        userValueStr: formatPrice(0),
      },
    };
  }

  if ([RewardType.PRODUCT_SPECIAL_PRICE].includes(reward.type)) {
    const product = {
      name: reward.data.productWithSpecialPrice.name,
      sku: reward.data.productWithSpecialPrice.sku,
      id: reward.data.productWithSpecialPrice.id,
    };

    const type = RewardDisplayType.PRODUCT;

    return {
      type,
      data: {
        product,
        quantity: reward.userPickData.qty,
        catalogPrice: reward.data.productWithSpecialPrice.grossPrice,
        userPrice: reward.data.value ?? 0,
        catalogPriceStr: formatPrice(reward.data.productWithSpecialPrice.grossPrice),
        userPriceStr: formatPrice(reward.data.value ?? 0),
        catalogValue:
            reward.data.productWithSpecialPrice.grossPrice * reward.userPickData.qty,
        userValue: (reward.data.value ?? 0) * reward.userPickData.qty,
        catalogValueStr: formatPrice(
            reward.data.productWithSpecialPrice.grossPrice * reward.userPickData.qty
        ),
        userValueStr: formatPrice((reward.data.value ?? 0) * reward.userPickData.qty),
      },
    };
  }

  if ([RewardType.DISCOUNT_PRODUCT].includes(reward.type)) {
    const product = {
      name: reward.data.productWithDiscount.name,
      sku: reward.data.productWithDiscount.sku,
      id: reward.data.productWithDiscount.id,
    };

    const type = RewardDisplayType.PRODUCT;

    const catalogPrice = reward.data.productWithDiscount.grossPrice;
    const userPrice = Math.floor((catalogPrice * (100 - reward.data.value ?? 0)) / 100);

    const quantity = reward.userPickData.qty;
    return {
      type,
      data: {
        product,
        quantity,
        catalogPrice,
        userPrice,
        catalogPriceStr: formatPrice(catalogPrice),
        userPriceStr: formatPrice(userPrice),
        catalogValue: catalogPrice * quantity,
        userValue: userPrice * quantity,
        catalogValueStr: formatPrice(catalogPrice * quantity),
        userValueStr: formatPrice(userPrice * quantity),
      },
    };
  }

  if ([RewardType.PRODUCT_HALF_PRICE].includes(reward.type)) {
    const type = RewardDisplayType.PRODUCTS;

    const data = reward.userPickData.products?.map((item) => {
      const product = {
        name: item.name,
        sku: item.sku,
        id: item.id,
      };

      const catalogPrice = item.grossPrice || 0;

      const userPrice = Math.floor(item.grossPrice * 0.5 || 0);

      return {
        product,
        quantity: item.quantity,
        catalogPrice,
        userPrice,
        catalogPriceStr: formatPrice(catalogPrice),
        userPriceStr: formatPrice(userPrice),
        catalogValue: catalogPrice * item.quantity,
        userValue: userPrice * item.quantity,
        catalogValueStr: formatPrice(catalogPrice * item.quantity),
        userValueStr: formatPrice(userPrice * item.quantity),
      };
    });

    return {
      type,
      data: data ?? [],
    };
  }

  if ([RewardType.PRODUCTS_FROM_CATEGORY].includes(reward.type)) {
    if (reward.type === RewardType.PRODUCTS_FROM_CATEGORY) {
      const type = RewardDisplayType.PRODUCTS;

      const data = reward.userPickData.products.map((item) => {
        const product = {
          name: item.name,
          sku: item.sku,
          id: item.id,
        };

        const userPrice = reward.data.value ? reward.data.value : item.grossPrice;

        return {
          product,
          quantity: item.quantity,
          catalogPrice: item.grossPrice,
          userPrice: userPrice,
          catalogPriceStr: formatPrice(item.grossPrice),
          userPriceStr: formatPrice(userPrice),
          catalogValue: item.grossPrice * item.quantity,
          userValue: userPrice * item.quantity,
          catalogValueStr: formatPrice(item.grossPrice * item.quantity),
          userValueStr: formatPrice(userPrice * item.quantity),
        };
      });

      return {
        type,
        data,
      };
    }
  }

  if ([RewardType.PRODUCT_TURNOVER].includes(reward.type)) {
    const product = {
      name: reward.userPickData.product?.name,
      sku: reward.userPickData.product?.sku,
      id: reward.userPickData.product?.id,
    };

    const type = RewardDisplayType.PRODUCTS;

    const productRewardSum = reward.userPickData.products.reduce((sum, item) => sum + item.grossPrice * item.quantity, 0);
    const maxDiscount = ((cartData.standardProductsCatalogValue * reward.data.maxDiscountValueOfPriceValue) / 100) * (reward.data.value / 100);
    const partOfOrder = cartData.standardProductsCatalogValue * (reward.data.maxDiscountValueOfPriceValue / 100);

    let userDiscount = productRewardSum > partOfOrder
      ? maxDiscount
      : (productRewardSum * (70 / 100));

    const data = reward.userPickData.products.map((item) => {
      const product = {
        name: item.name,
        sku: item.sku,
        id: item.id,
      };


      const catalogValue = item.grossPrice * item.quantity;
      let discountPerProduct = userDiscount > (catalogValue * 0.7) ? (catalogValue * 0.7) : userDiscount;
      const userValue = catalogValue - discountPerProduct ?? 0;
      const userPrice = userValue / item.quantity;

      userDiscount = userDiscount - discountPerProduct;

      if (userPrice <= 0) {
        userDiscount = 0;
      }

      return {
        product,
        quantity: item.quantity,
        catalogPrice: item.grossPrice,
        userPrice: userPrice < 0 ? 0 : userPrice,
        catalogPriceStr: formatPrice(item.grossPrice),
        userPriceStr: formatPrice(userPrice < 0 ? 0 : userPrice),
        catalogValue: item.grossPrice * item.quantity,
        userValue: userValue < 0 ? 0 : userValue,
        catalogValueStr: formatPrice(item.grossPrice * item.quantity),
        userValueStr: formatPrice(userValue < 0 ? 0 : userValue),
      };
    });

    return {
      type,
      data,
    };
  }

  if ([RewardType.EXTRA_PRODUCT].includes(reward.type)) {
    const requiredProduct = cartData.allProducts.find((cartProduct) => {
      return cartProduct.product.id === reward.data.productInCart.id;
    });

    const type = RewardDisplayType.PRODUCT;
    if (requiredProduct) {
      if (requiredProduct.quantity >= reward.data.minQty) {
        const product = {
          name: reward.data.productToBeAddedToCart.name,
          sku: reward.data.productToBeAddedToCart.sku,
          id: reward.data.productToBeAddedToCart.id,
        };

        const catalogPrice = reward.data.productToBeAddedToCart.grossPrice;

        const userPrice =
            reward.data.valueType === DiscountType.PROMO
                ? reward.data.value
                : (catalogPrice * (100 - reward.data.valueDiscount ?? 0)) / 100;

        const quantity = reward.userPickData.qty;

        return {
          type,
          data: {
            product,
            quantity: quantity,
            catalogPrice,
            userPrice,
            catalogPriceStr: formatPrice(catalogPrice),
            userPriceStr: formatPrice(userPrice),
            catalogValue: catalogPrice * quantity,
            userValue: userPrice * quantity,
            catalogValueStr: formatPrice(catalogPrice * quantity),
            userValueStr: formatPrice(userPrice * quantity),
          },
        };
      }
    }
  }

  return null;
}

export function formatRewardSummary(reward: IReward) {
  if (RewardType.COUPON === reward.type) {
    const valueType = reward.data.valueType;
    const couponValue = reward.data.value / 100;
    const minOrderValue = reward.data.minOrderValue / 100;
    return `Kupon ${reward.data.valueType === CouponValueType.VALUE ? 'zniżkowy' : 'produktowy'} na wartość ${couponValue}zł`;
  }

  if (RewardType.DISCOUNT_ORDER === reward.type) {
    return `${getRewardName(reward.type)} o wartości: ${reward.data.value}%`;
  }

  if (RewardType.DAYS_NUMBER === reward.type) {
    return `${reward.data.daysQty} dni wysyłki w cenie ${
        reward.data.shippingCostValue / 100
    } zł przy minimalnej wartości zamówienia ${reward.data.minValue / 100} zł.`;
  }

  if (RewardType.MONTHS_NUMBER === reward.type) {
    return `${reward.data.monthsQty} miesięcy wysyłki w cenie ${
        reward.data.shippingCostValue / 100
    } 
      zł przy minimalnej wartości zamówienia ${reward.data.minValue / 100} zł.`;
  }

  if (RewardType.DISCOUNT_SHIPPING === reward.type) {
    if (reward.data.valueType === DiscountType.PROMO) {
      return `Promocyjny koszt wysyłki: ${reward.data.value / 100} zł`;
    }

    if (reward.data.valueType === DiscountType.DISCOUNT) {
      return `Rabat na koszt wysyłki: -${reward.data.value}%`;
    }
  }
}

export function cartToDTO(cart: CalculatedCart) {
  const standardProducts = cart.standardProducts.map((cartProduct) => {
    return {
      product: cartProduct.product.id,
      quantity: cartProduct.quantity,
      catalogPrice: cartProduct.promoPrice ? cartProduct.promoPrice : cartProduct.catalogPrice ,
      catalogValue: cartProduct.promoPrice ? cartProduct.promoPrice * cartProduct.quantity : cartProduct.catalogValue,
      userPrice: cartProduct.userPrice,
      userValue: cartProduct.userValue,
    };
  });

  const marketingProducts = cart.marketingProducts.map((cartProduct) => {
    return {
      product: cartProduct.product.id,
      quantity: cartProduct.quantity,
      catalogPrice: cartProduct.catalogPrice,
      catalogValue: cartProduct.catalogValue,
      userPrice: cartProduct.userPrice,
      userValue: cartProduct.userValue,
    };
  });

  const freeProducts = cart.freeProducts.map((cartProduct) => {
    return {
      product: cartProduct.product.id,
      quantity: cartProduct.quantity,
      catalogPrice: 0,
      catalogValue: 0,
      userPrice: 0,
      userValue: 0,
    };
  });

  const voucherProducts = cart.voucherProducts.map((cartProduct) => {
    return {
      product: cartProduct.product.id,
      quantity: cartProduct.quantity,
      catalogPrice: cartProduct.catalogPrice,
      catalogValue: cartProduct.catalogValue,
      userPrice: 0,
      userValue: 0,
    };
  });

  const specialOffers = cart.specialOffers.map((cartSpecialOffer) => {
    const rewards = cartSpecialOffer.chosenRewards.map((chosenReward) => {
      let userSpecialOfferRewardType = UserSpecialOfferRewardType.CURRENT_ORDER;

      if (
          [
            RewardType.COUPON,
            RewardType.DAYS_NUMBER,
            RewardType.MONTHS_NUMBER,
          ].includes(chosenReward.reward.type)
      ) {
        userSpecialOfferRewardType = UserSpecialOfferRewardType.LATER_USE;
      }

      const calculatedData =
          chosenReward.calculated.type === RewardDisplayType.PRODUCT
              ? {
                ...chosenReward.calculated.data,
                product: chosenReward.calculated.data.product?.id,
              }
              : chosenReward.calculated.type === RewardDisplayType.PRODUCTS
                  ? chosenReward.calculated.data.map((elem) => {
                    return {
                      ...elem,
                      product: elem.product?.id,
                    };
                  })
                  : null;

      return {
        userSpecialOfferRewardType,
        reward: chosenReward.reward.data.id,
        userPickData: {
          ...chosenReward.reward.userPickData,
          ...(chosenReward.reward.userPickData?.product
              ? { product: chosenReward.reward.userPickData?.product.id }
              : null),
          ...(chosenReward.reward.userPickData?.products
              ? {
                products: chosenReward.reward.userPickData?.products.map(
                    (product) => product.id
                ),
              }
              : null),
        },
        calculatedData,
        type: chosenReward.calculated.type,
      };
    });

    return {
      offer: cartSpecialOffer.offer.id,
      rewards,
    };
  });

  const totals = {
    standardProductsCatalogValue: cart.standardProductsCatalogValue,
    standardProductsUserValue: cart.standardProductsUserValue,
    marketingProductsCatalogValue: cart.marketingProductsCatalogValue,
    marketingProductsUserValue: cart.marketingProductsUserValue,
    marketingAndStandardProductsCatalogValue:
    cart.marketingAndStandardProductsCatalogValue,
    marketingAndStandardProductsUserValue: cart.marketingAndStandardProductsUserValue,
    rewardsCatalogValue: cart.rewardsCatalogValue,
    rewardsUserValue: cart.rewardsUserValue,
    allProductsCatalogValue: cart.allProductsCatalogValue,
    allProductsUserValue: cart.allProductsUserValue,
    shippingCost: cart.shippingCost,
    virtualWalletDiscount: cart.virtualWalletDiscount,
    maxVirtualWalletDiscount: cart.maxVirtualWalletDiscount,
    total: cart.total,
    payment: cart.payment,
  };

  const cartDTO: ICartDTO = {
    marketingProducts,
    freeProducts,
    voucherProducts,
    standardProducts,
    specialOffers,
    totals,
  };

  return cartDTO;
}
