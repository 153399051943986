import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'app/redux/store';

export interface IConfig {
  discount: number;
  discountLevel0: number;
  discountLevel1: number;
  discountLevel2: number;
  discountLevel3: number;
  discountLevel4: number;
  discountLevel5: number;
  discountLevel6: number;
  dpdCODCost: number;
  dpdShippingCost: number;
  inpostShippingCost: number;
  taxRate: number;
  virtualWalletMaxDiscount: number;
}

export interface IConfigState {
  config: IConfig;
}

const initialState: IConfigState = {
  config: null,
};

export const configSlice = createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
    setConfigData: (state, action: PayloadAction<IConfig>) => {
      state.config = action.payload;
    },
  },
});

export const { setConfigData } = configSlice.actions;

export const configSelector = (state: AppState) => state.configSlice;

export default configSlice;
